@import './Colors.css';

body {
    background: var(--url);
}

//home page START
.home_madad {
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 180px;
}

.home_madad .vc_column_container {
    width: 80%;
}

.home_madad img {
    width: 150px;
    margin-bottom: 20px;
}
@media (max-width: 1200px) {
    .home_madad h1 br{
        display: none;
    }
}
//home page END

:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --dark: #4B4747;
    --text-light-gray: #C9C3C4;
}

.LikeDisLikeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    top: 0px;
    width: 70px;
    position: absolute;
    height: 100%;
    right: 0px;
    overflow: hidden;
    background-color: #e6e6e6;
}

.LikeDisLikeContainer .top {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgb(178, 178, 178);
    transition: .3s;
}

.LikeDisLikeContainer .top:hover {
    background-color: #90DE9D;
    transition: .3s;
}

.LikeDisLikeContainer .bottom {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
}

.LikeDisLikeContainer .bottom:hover {
    background-color: #F58D8D;
    transition: .3s;
}

.LikeDisLikeContainer .number {
    color: white;
}


.LikeDisLikeContainer .bottom .number {
    position: relative;
    top: -2px;
    right: 5px;
}

.LikeDisLikeContainer .bottom .icon {
    position: relative;
    top: 0px;
    right: 1px;
}

.LikeDisLikeContainer .top .number {
    position: relative;
    top: 4px;
    right: 5px;
}

.LikeDisLikeContainer .top .icon {
    position: relative;
    top: 0px;
    right: 1px;
}

.Price {
    color: var(--third-colo);
    position: absolute;
    left: 0;
    top: 22px;
}

.GlutenFreeIcon {
    width: 30px;
    background-color: white;
    border-radius: 100px;
    position: absolute;
    z-index: 10;
    left: 10px;
}

.EditItemContainer {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4B4747;
    z-index: 1000;
}

.MainContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}


.Number {
    color: var(--text-light-gray);
    font-size: 14px;
    letter-spacing: -.1em;
    position: relative;
    right: 2px;
}


.Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    top: 2px;
    width: 50px;
    position: absolute;
    height: 100%;
    right: 5px;
}

.Icon img {
    width: 20px;
    height: auto;
    position: relative;
    right: 1px;
}

.TextContainer {
    display: flex;
    flex-direction: column;
    margin-right: 60px;
    margin-left: 10px;
}


.Container {
    width: 90%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background-color: #ffffff;
    border-radius: 100px;
    margin-top: 25px;
    box-shadow: -2px 5px 20px rgba(0, 0, 0, 0.10);
    padding-right: 20px;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 70px;
    overflow: hidden;
}

.Container h1 {
    text-align: right;
    color: var(--dark);
    font-size: 19px;
    line-height: 19px;
    font-weight: 900;
    direction: rtl;
    right: 80px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.Container h2 {
    margin-top: 5px;
    text-align: right;
    color: #667080;
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
    direction: rtl;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

@media (min-width: 1301px) {}

@media (max-width: 1300px) and (min-width: 769px) {}

@media (max-width: 768px) and (min-width: 576px) {}

@media (max-width: 575px) and (min-width: 381px) {}

@media (max-width: 380px) {}
:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --background-color: #F3F3F3;
}

.soon {
    position: absolute;
    font-size: 26px;
    font-weight: 800;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
}

.Newicon {
    position: absolute;
    left: -10px;
    top: -15px;
}

.MainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.NotificationsAndRecipes {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 90%;
    max-width: 400px;
    gap: 15px;
    margin-top: 15px;
}

.Recipes {
    width: 100%;
    height: 120px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    flex-direction: column;
    cursor: pointer;
    position: relative;
}

.Recipes svg {
    margin-top: 7px;
    height: 50px;
    margin-bottom: 0px;
}

.Recipes .text {
    color: white;
    font-weight: 800;
    font-size: 18px;
}
:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --dark: #4B4747;
}

.Menu {}

.Version {
    position: absolute;
    bottom: 10px;
    font-size: 10px;
}

.MainContainer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background-color: rgba(227, 231, 233, 0.75);
    position: fixed;
    left: 0px;
    width: 100vw;
    z-index: 1000;
    backdrop-filter: blur(2px);
}

.HeaderContiner {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Right {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 20px;

}

.Left {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
}

.HiMenuAlt3 {
    cursor: pointer;
    color: var(--dark)
}

.FaChevronLeft {
    cursor: pointer;
    color: var(--dark)
}

.Center {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LogoMenu img {
    width: 60px;
}



.BottomSection {
    margin-top: 100px;
    border-top: 1px solid #C9C3C4;
}
:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E9EAEB;
    --gray-color-deep: #C3C6C9;

}

button {
    background: none !important;
    border: none !important;
}

.Trash {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
}

.Edit {
    position: absolute;
    top: 15px;
    left: 50px;
    cursor: pointer;
}

.MainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.MainContainer input {
    width: 100%;
    font-family: "Heebo", sans-serif !important;
}

.TextField {
    background-color: #E9EAEB;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}

.TextField .MuiInputBase-root {
    width: 100% !important;
}

.IconSearchContainer {
    padding-right: 15px;
    padding-left: 10px;
    border-left: 1px solid var(--gray-color-deep);
}

.Notification {
    position: relative;
    height: 120px;
    width: 100%;
    /* max-width: 400px; */
    border-radius: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    direction: rtl;
}

.IconContainer {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 50px;
    margin-right: 15px;
    margin-left: 10px;
}

.TextContainer {
    width: 60%;
    text-align: right;
    direction: rtl;
}

.TextContainer h1 {
    color: white;
    font-size: 18px;
    line-height: 20px;
    font-weight: 900;
    padding-left: 20px;
}

.IconContainer img {
    max-height: 70%;
}
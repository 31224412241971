/* :root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
} */

.TimePickerContiner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-direction: column;
    direction: ltr !important;
    position: relative;
 
}

/* :root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
} */


.ApproveDaySelect {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 4px 10px rgb(212, 212, 212);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    top: 60px;
}

.Approve {
    cursor: pointer;
    color: var(--first-color);
    margin-left: 30px;
    font-size: 20px;
    font-weight: 600;
}

.Cancel {
    cursor: pointer;
    color: #393F48;
    margin-right: 30px;
    font-size: 20px;
    font-weight: 600;
}

@media (min-width: 1301px) {}

@media (max-width: 1300px) and (min-width: 769px) {}

@media (max-width: 768px) and (min-width: 576px) {}

@media (max-width: 575px) and (min-width: 381px) {}

@media (max-width: 380px) {}

@media (min-width: 1301px) {}

@media (max-width: 1300px) and (min-width: 769px) {}

@media (max-width: 768px) and (min-width: 576px) {}

@media (max-width: 575px) and (min-width: 381px) {}

@media (max-width: 380px) {}
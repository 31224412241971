:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --dark: #4B4747;
}

.MainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 199;
    cursor: pointer;
}

.AddDish {
    width: 80%;
    max-width: 500px;
    border-radius: 20px 20px 0px 0px;
    background-color: #F5C037;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0px 0px 5px #bd9122;
}

.AddDish h1 {
    color: white;
    margin-right: 12px;
    font-size: 18px;
    position: relative;
    top: 1px;
}

@media (min-width: 1301px) {}

@media (max-width: 1300px) and (min-width: 769px) {}

@media (max-width: 768px) and (min-width: 576px) {}

@media (max-width: 575px) and (min-width: 381px) {}

@media (max-width: 380px) {}
:root {
  --first-color: #49C9D1;
  --second-color: #C5A0FA;
  --third-colo: #F5C037;
  --gray-color: #4B4747;
  --light-gray: #E3E7E9;
}

.SliderContiner {
  width: 400px;
}


:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --dark: #4B4747;
}

.MainContainer {
      display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 180px;
}

.Container {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px 0px 10px;
    position: relative;
    flex-direction: column;
    background-color: #E9EAEB;
    border-radius: 100px;
}

.Icon {
    position: relative;
    height: auto;
}

.Container h1 {
    text-align: center;
    color: var(--first-color);
    font-size: 16px;
    line-height: 15px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    position: absolute;
    bottom: 16px;
    direction: rtl;
    top: 90px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.Container h2 {
    color: var(--gray-color);
    font-weight: 600;
    font-size: 14px;
    margin-top: 2px;
    position: relative;
    top:3px;
}
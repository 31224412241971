:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --dark: #4B4747;
}
.Logo img{
    padding: 10px;
    height: 70px;
}
.Logo {
   padding: 10px;
}

.MainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    max-width: 1300px;
    /* background-color: var(--first-color); */
}

.Container {
    height: 60px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

}

.Icon {
    position: relative;
    height: 100%;
    width: 100px;
}

.Icon img {
    position: relative;
    height: 100%;
}

.Container h1 {
    text-align: center;
    color: white;
    font-size: 30px;
    line-height: 30px;
    font-weight: 900;
    direction: rtl;
}
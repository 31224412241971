:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --background-color: #F3F3F3;
}

.NotificationItem {
    margin-bottom: 20px;
    width: 90vw;
    max-width: 400px;
}

.ContactInfo {
    width: 100%;
    max-width: 1000px;
    padding-right: 40px;
}

.ContactInfo h1 {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 15px;
    margin-top: 10px;
}

.ContactInfo li {
    font-size: 18px;
    margin-bottom: 10px;
}

.BottomBackImage {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 230px;
    z-index: -10;
}

.BottomBackImage img {
    width: 100%;
}

.TopBackImage {
    position: absolute;
    top: 120px;
    left: 7px;
    width: 70px;
    z-index: -10;
}

.TopBackImage img {
    width: 100%;
}

.MainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.LoadingMainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #313131;
    height: 100vh;
}

.LoadingMainContainer img {
    width: 100%;
    max-width: 400px;
}

.CloseSing img {
    position: relative;
    width: 200px;
    margin-top: 5vh;
    animation-name: SignAnimations;
    animation-duration: .5s;
}



@keyframes SignAnimations {
    0% {
        right: calc(-100vw - 200px);
    }

    100% {
        right: 0px;
    }
}
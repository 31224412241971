:root {
  --first-color: #49C9D1;
  --second-color: #C5A0FA;
  --third-colo: #F5C037;
  --gray-color: #4B4747;
  --light-gray: #E3E7E9;
  --dark: #4B4747;
}

.MainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
}

.Container {
  display: flex;
  align-items: center;
  direction: ltr;
}


@media (min-width: 1461px) {
  .Container {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1460px) and (min-width: 769px) {
  .Container {
    width: 90%;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .Container {
    width: 100%;
  }
}

@media (max-width: 575px) and (min-width: 381px) {
  .Container {
    width: 100%;
  }
}

@media (max-width: 380px) {
  .Container {
    width: 100%;
  }
}
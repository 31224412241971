:root {
  --first-color: #49C9D1;
  --second-color: #C5A0FA;
  --third-colo: #F5C037;
  --gray-color: #4B4747;
  --light-gray: #E3E7E9;
  --dark: #4B4747;
}

.MainContainer {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.FlexContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.Container {
  width: 140%;
  direction: rtl !important;
}

.Slider {
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
  /* direction: rtl; */
}

.LinearProgress {
  position: absolute;
  width: 47%;
  bottom: -1px;
  z-index: 1;
  transition: .3s;
}

@media (min-width: 1301px) {}

@media (max-width: 1300px) and (min-width: 1001px) {}

@media (max-width: 1000px) and (min-width: 767px) {}

@media (max-width: 766px) and (min-width: 481px) {}

@media (max-width: 480px) {}
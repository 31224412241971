@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap");

@import './Colors.css';



.ElergenimDetails strong {
    color: white;
}

.MuiAccordion-rounded::before {
    display: none !important;
}

.MuiInputBase-multiline {
    padding: 0px !important;
    border-bottom-color: '#a7a7a7';
}

.rc-time-picker-input {
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: var(--gray-color)
}

:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --background-color: #F3F3F3;
}

.rdp-day_selected {
    background-color: var(--first-color) !important;
}

.react-dropdown-select-option {
    background-color: var(--first-color) !important;
    border-radius: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 2px !important;
}

.react-dropdown-select-option-remove {
    top: 1px;
    position: relative;
}

body {
    background-color: var(--background-color) !important;
    direction: rtl;
    font-family: "Heebo", sans-serif !important;
}


// BM MENU START

/* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    left: 20px;
    display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}



.bm-cross-button {
    display: none !important;
    right: 16px !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}



/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: white !important;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    padding-right: 10px !important;
    padding-top: 10px !important;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

// BM MENU END
.star {
    -webkit-animation: scale 4s infinite linear;
}

@-webkit-keyframes scale {
    0% {
        -webkit-transform: scale(100%);
    }

    50% {
        -webkit-transform: scale(110%);
    }

    100% {
        -webkit-transform: scale(100%);
    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader span {
    color: rgb(139, 139, 139);
}

.CircularProgress {
    top: 30vh;
    position: absolute;
    text-align: center;
}

.FlexAllCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
}

.Flex80PercentWidht {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80vw;
}

strong {
    color: var(--secondaryColor);
}


p {
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    margin-block-start: 0;
    margin-block-end: 0;
}

h1 {
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.02em;
    font-weight: 500;
    color: var(--primaryColor);
    margin-block-start: 0;
    margin-block-end: 0;
}

h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: var(--primaryColor);
    margin-block-start: 0;
    margin-block-end: 0;
}

h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--primaryColor);
    margin-block-start: 0;
    margin-block-end: 0;
}


a {
    text-decoration: none !important;
    transition: 0.2s;
    color: var(--primaryColor);
}

a:hover {
    color: var(--secondaryColor);
    transition: 0.2s;
}

.read_more {
    font-size: 20px;
    cursor: pointer;
    margin-top: 10px;
}

._access-icon {
    font-size: 28px !important;
    line-height: 35px !important;
}


.TabsArrowLeft::before,
.TabsArrowRight::before {
    display: none;
}

.TabsArrowLeft {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.TabsArrowRight {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.DaysSliderMainContainer .slick-current {
    border-bottom: 3px solid var(--gray-color);
}

.DaysSliderMainContainer .OpenCloseBt {
    display: none;
}

.DaysSliderMainContainer .slick-current .OpenCloseBt {
    display: block !important;
}

.DaysSliderMainContainer .slick-current h1 {
    transition: .5s;
    color: var(--gray-color) !important;
    font-size: 22px !important;
}

.DaysSliderMainContainer .slick-current {
    border-bottom: none;
}

.CoffeShopSliderMainContainer .slick-current h1 {
    color: white !important;
}

.DaysSliderMainContainer img {
    transition: .5s;
    opacity: .2 !important;
}

.DaysSliderMainContainer .slick-current img {
    transition: .5s;
    opacity: 1 !important;
}

.TabsArrowLeft {
    left: 45vw;
}

.TabsArrowRight {
    right: 45vw;
}

@media (min-width: 767px) {
    .NotificationsAndRecipes .slick-slider {
        width: 250px;
    }
}

@media (max-width:766px) {
    .NotificationsAndRecipes .slick-slider {
        width: 200px;
    }
}

@media (min-width: 1301px) {}

@media (max-width: 1300px) and (min-width: 1001px) {}

@media (max-width: 1000px) and (min-width: 767px) {}

@media (max-width: 766px) and (min-width: 481px) {}


// ProjectsSlider END
:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E9EAEB;
    --gray-color-deep: #C3C6C9;

}

.MainContainer {
    display: flex;
    position: absolute;
    bottom: 15px;
    width: 100%;
    z-index: 1;
    height: 100%;
    justify-content: center;
    bottom: 0px;
}

.Container {
    display: flex;
    align-items: flex-end;
    bottom: 10px;
    position: relative;
}

.Item {
    width: 10px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.651);
    border-radius: 100px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
}

.SelectedItem {
    cursor: pointer;
    width: 40px;
    height: 4px;
    background-color: rgb(255, 255, 255);
    border-radius: 100px;
    margin-left: 2px;
    margin-right: 2px;
}

:root {
    --primaryColor: #000027 !important;
    --secondaryColor: #b37d29 !important;
    --tertiaryColor: #ffd768 !important;
    --secondaryColorHover: #9b6a1b !important;
    --secondaryColorLight: #f7e5c8 !important;
    --placeholderColor: rgb(125, 125, 125) !important;
}


.MainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.MainContainer h1 {
    color: rebeccapurple;
    font-size: 20px !important;
    margin-top: 40px;
}

.DataTitle {
    font-size: 24px;
    line-height: 24px;
    margin-top: 20px;
    margin-top: 20px;
}

.Data {
    direction: ltr;
    color: red;
    font-size: 30px;
    line-height: 30px;
    margin-top: 20px;
    margin-top: 10px;
}
:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
}

.EnterButtonConatiner {
    position: absolute;
    bottom: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
}

.EnterButton {
    width: 250px;
    height: 65px;
    background-color: var(--first-color);
    box-shadow: -2px 5px 20px rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EnterButton h1 {
    color: white;
    font-weight: 900;
    font-size: 25;
}

/* style={{ width: '250px', height: '65px', left: 0, top: 0, background: '#49C9D1', boxShadow: '-2px 5px 20px rgba(0, 0, 0, 0.10)', borderRadius: 20 }} */
:root {
  --first-color: #49C9D1;
  --second-color: #C5A0FA;
  --third-colo: #F5C037;
  --gray-color: #4B4747;
  --background-color: #F3F3F3;
}

.MainContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.Spinner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.StarsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.Postion1 {
  left: 20px;
  top: 50px;
}

.Postion2 {
  left: 65px;
  top: 180px;
}

.Postion3 {
  left: 260px;
  top: 230px;
}

.Postion4 {
  right: 70px;
  top: 0px;
}

.Postion5 {
  right: 45px;
  top: 120px;
}

/* STAR1 */
.Star1 {
  -webkit-animation: scale 3s infinite linear !important;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

}

.Star1 path {
  fill: var(--first-color);
  animation: Star1Colors 3s infinite linear !important;
}

@keyframes Star1Colors {
  0% {
    fill: var(--first-color);
  }

  25% {
    fill: var(--first-color);
  }

  100% {
    fill: var(--second-color);
  }
}

@-webkit-keyframes Star1Colors {
  0% {
    fill: var(--first-color);
  }

  25% {
    fill: var(--first-color);
  }

  100% {
    fill: var(--second-color);
  }
}

/* STAR1 ENDS */



/* STAR2 */
.Star2 {
  -webkit-animation: scale 3s infinite linear !important;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

}

.Star2 path {
  fill: var(--first-color);
  animation: Star2Colors 3s infinite linear !important;
}

@keyframes Star2Colors {
  0% {
    fill: var(--second-color);
  }

  25% {
    fill: var(--third-colo);
  }

  100% {
    fill: var(--second-color);
  }
}

@-webkit-keyframes Star2Colors {
  0% {
    fill: var(--second-color);
  }

  25% {
    fill: var(--third-colo);
  }

  100% {
    fill: var(--second-color);
  }
}

/* STAR2 ENDS */


@keyframes scale {
  0% {
    -webkit-transform: scale(100%);
  }

  50% {
    -webkit-transform: scale(150%);
  }

  100% {
    -webkit-transform: scale(100%);
  }
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(100%);
  }

  50% {
    -webkit-transform: scale(110%);
  }

  100% {
    -webkit-transform: scale(100%);
  }
}


.LoginConatiner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;

}

.LoginText {
  position: absolute;
  transition: .4s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.LoginText h2 {
  font-size: 24px;
  font-weight: 900;
  color: var(--second-color);
}


.LoginText h3 {
  font-size: 18px;
  font-weight: 400;
  color: var(--gray-color);
  line-height: 30px;
}


.EnterButton {
  position: absolute;
  bottom: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginImageAndStars {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  top: 100px;
  width: 350px;
  transition: .4s;
  z-index: 1;
}

.StarsMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  flex-direction: column;
  height: 100%;
}

.LogoMenu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: -100;
}

.LogoMenu img {
  filter: drop-shadow(4px 4px 0px #dddddd77);
}

.LoginImageAndStars img {
  width: 70%;
  max-width: 350px;
}

.Spinner img {
  width: 70.3%;
}



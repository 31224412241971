:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --background-color: #F3F3F3;
}

.noRecipes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.hi {
    margin-top: 40px;
    margin-bottom: 30px;
}

.bodyText {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
    color: #4B4747;
}

.bigBodyText {
    color: #49C9D1;
    font-size: 24px;
    font-weight: 800;
    margin-top: 10px;
}

.imgContainer {
    width: 100vw;
    max-width: 400px;
    margin-bottom: 40px;
}

.imgContainer img {
    width: 100%;
}

.MainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 40px;
}

.RecipeLink {
    border-radius: 18px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 200%;
    flex-direction: column;
    cursor: pointer;

}

.icon {
    margin-bottom: 15px;
}


.title {
    color: white;
    font-weight: 800;
    font-size: 18px;
}

.RecipesMenuContainer {
    width: 100%;
    display: flex;
    gap: 15px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 400px;
}

.LinksContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 15px;
}
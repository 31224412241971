:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --dark: #4B4747;
}

.MainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Container {
    width: 90%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background-color: #ffffff;
    border-radius: 100px;
    margin-top: 25px;
    box-shadow: -2px 5px 20px rgba(0, 0, 0, 0.10);
    height: 80px;
    padding-right: 20px;
    cursor: pointer;
}

.Icon {
    position: relative;
    height: auto;
}

.Container h1 {
    text-align: right;
    color: var(--dark);
    font-size: 19px;
    line-height: 19px;
    font-weight: 900;
    direction: rtl;
    position: relative;
    right: 15px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.Container h2 {
    font-size: 17px;
    line-height: 19px;
    position: relative;
    right: 25px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.Title{
    display: flex;
}
@media (min-width: 1301px) {

}

@media (max-width: 1300px) and (min-width: 769px) {}

@media (max-width: 768px) and (min-width: 576px) {}

@media (max-width: 575px) and (min-width: 381px) {}

@media (max-width: 380px) {}
.FixedBack {
    background-color: #ffffff;
    width: 100%;
    height: 150%;
    position: fixed;
    z-index: -5000;
}

.HiddenButton {
    position: absolute;
    z-index: -1000;
    opacity: 0;
    top: -200;
}

.SpinnerAnimation {
    position: absolute;
    top: 390px;
}

.SpinnerAnimation img {
    width: 100px;
}

.AlertIcons {
    width: 100%;

}

.AlertIcon {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;
}

.AlertIconHover:hover {
    background-color: #49c8d127 !important;
    transition: .3s;
}

.AlertIconsRow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.DateAndTimeContiner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.Date {
    cursor: pointer;
    border: 1px solid var(--gray-color);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 10px 5px 10px;
    width: 200px;
}

.Time {
    cursor: pointer;
    border: 1px solid var(--gray-color);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 10px 5px 10px;
    width: 150px;
}

.Err {
    color: red;
    margin-top: 3px;
}

.TimePicker input {
    border: none !;
}

.Time input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(65%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
    background-color: none !important;
}

.Time input[type="time"] {
    border: none;
    text-align: center;
    width: 100%;
    background-color: white !important;
}

.Helper {
    font-size: 10px;
    width: 100%;
    margin-bottom: 5px;
}

.Gap {
    width: 15px;
}

.Text {
    font-weight: 600;
    line-height: 16px;
    font-size: 16px;
    position: relative;
    top: 2px;
}

.ArrowDown {
    width: 100%;
    direction: ltr;
}

.EditAlertContainer {
    margin-top: 0px;
}

.DateAndTime {
    margin-top: 20px;
}

.AletIconsRow {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

.AletIcon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100px;
}

.AletIcon:hover {
    background-color: #49C9D1;
    transition: -.5px;
}

.BigDivider {
    height: 10px;
    background-color: var(--light-gray);
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0px;
}

.ToggleGlotenFree {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
}

.ToggleGlotenFree .Icon {
    margin-left: 7px;
    cursor: pointer;
    position: relative;
    top: -1px;
}

.ToggleGlotenFree h1 {
    margin-left: 10px;
    font-weight: 600;
    font-size: 18px;
    color: var(--third-colo);
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.ToggelCreateAlert {
    display: flex;
    margin-top: 40px;
}

.ToggelCreateAlert .Icon {
    margin-left: 7px;
    cursor: pointer;
    position: relative;
    top: -1px;
}

.ToggelCreateAlert h1 {
    font-weight: 600;
    font-size: 18px;
    color: var(--first-color);
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.SaveButton {
    background-color: var(--third-colo);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    border-radius: 20px;
    width: 250px;
    cursor: pointer;
    font-weight: 700;
    box-shadow: 1px 5px 10px #E3E7E9;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.DeleteButton {
    margin-top: 60px;
    background-color: rgb(221, 47, 47);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    width: 200px;
    cursor: pointer;
    font-weight: 500;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.MainContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: absolute;
    transition: right .2s;
    z-index: 200;
    background-color: white;
}

.DropdownSelectChild {
    border-bottom: 1px solid !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.DropdownSelectChild svg {
    color: #49C9D1;
}

.DropdownSelectChild span {
    font-size: 18px;
    font-weight: 900;
}

.DropdownSelectChild input {
    font-size: 18px;
    font-weight: 600;
}

.Title {
    border-bottom: 2px solid var(--dark);
}

.EditItemContainer {
    margin-top: 100px;
    width: 100%;
    max-width: 600px;

}

.Form {
    padding: 30px;
}

.BottomGap {
    height: 200px;
    width: 100%;
    background-color: #C5A0FA;
}

.HideEditContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    padding: 30px 30px 0px 0px;
}

.HideEdit {
    cursor: pointer;
}

.Background {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -200;
}

@media (min-width: 1301px) {}

@media (max-width: 1300px) and (min-width: 769px) {}

@media (max-width: 768px) and (min-width: 576px) {}

@media (max-width: 575px) and (min-width: 381px) {}

@media (max-width: 380px) {}
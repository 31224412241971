:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E9EAEB;
    --gray-color-deep: #C3C6C9;

}

.Slider {
    width: 100%;
}

.SliderContainer {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 90%;
    max-width: 400px;
    margin-top: 10px;
}
:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --dark: #4B4747;
    --text-light-gray: #C9C3C4;

}

.MainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 22px;
    position: relative;
    top: 2px;
}

.Number {
    color: var(--text-light-gray);
    font-size: 14px;
    letter-spacing: -.1em;
    position: relative;
    right: 0px;
}



.Container {
    width: 90%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background-color: #ffffff;
    border-radius: 20px;
    margin-top: 25px;
    box-shadow: -2px 5px 20px rgba(0, 0, 0, 0.10);
    height: 70px;
    padding-right: 20px;
    cursor: pointer;
   
}

.Container h1 {
    text-align: right;
    color: var(--dark);
    font-size: 19px;
    line-height: 19px;
    font-weight: 900;
    direction: rtl;
    position: absolute;
    right: 60px;
    padding-left: 5px;
}

.Container h2 {
    text-align: right;
    color: var(--text-light-gray);
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    direction: rtl;
    position: absolute;
    left: 15px;
    bottom: 13px;
}

@media (min-width: 1301px) {}

@media (max-width: 1300px) and (min-width: 769px) {}

@media (max-width: 768px) and (min-width: 576px) {}

@media (max-width: 575px) and (min-width: 381px) {}

@media (max-width: 380px) {}
:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --dark: #4B4747;
}

.MainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    left: 10px;
    top: 0px;
}

.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    width: 34px;
    height: 12px;
    border-radius: 100px;
    transition: .3s ease;
}

.BtHandel {
    position: absolute;
    transition: 0.35s cubic-bezier(0.9, 1.6, 0.25, 1);
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 100px;
    border: 2px solid;
    
}

.afterAnimation {
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13), 0 3px 3px hsla(0, 0%, 0%, 0.05);
    -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
}
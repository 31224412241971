:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --dark: #4B4747;
}

.MainContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--light-gray);
    cursor: pointer;
}

.Container {
    overflow: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Container h1 {
    font-size: 15px;
    font-weight: 900;
    color: #4b474723;
    transition: .5s !important;
    direction: rtl;
}
:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;

}

.MainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.MenuItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 40px;
    cursor: pointer;
}

.Icon {
    margin-left: 20px;
}

.Text {
    color: '#667080';
    font-size: 18;
    font-family: 'Heebo';
    font-weight: 900;
    color: var(--gray-color);
}


:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E3E7E9;
    --dark: #4B4747;
}

.MainContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom:80px;
}

.LinksContainer {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
}

.TitleAndListLink {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Title {
    border-bottom: 1px solid #4B4747;
    font-size: 18px;
    font-weight: 900;
    text-align: left;
    padding-right: 5%;
}

.SeeAll {
    padding-left: 5%;
    color: var(--first-color);
    font-size: 14px;
    font-weight: 500;
}

@media (min-width: 1301px) {}

@media (max-width: 1300px) and (min-width: 769px) {}

@media (max-width: 768px) and (min-width: 576px) {}

@media (max-width: 575px) and (min-width: 381px) {
    .TitleAndListLink {
        width: 100vw;
    }

}

@media (max-width: 380px) {
    .TitleAndListLink {
        width: 100vw;
    }

}
:root {
    --first-color: #49C9D1;
    --second-color: #C5A0FA;
    --third-colo: #F5C037;
    --gray-color: #4B4747;
    --light-gray: #E9EAEB;
    --gray-color-deep: #C3C6C9;

}

.MainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 400px;
}

.MainContainer input {
    width: 100%;
    font-family: "Heebo", sans-serif !important;
}

.TextField {
    background-color: #E9EAEB;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}

.TextField .MuiInputBase-root {
    width: 100% !important;
}

.IconSearchContainer {
    padding-right: 15px;
    padding-left: 10px;
    border-left: 1px solid var(--gray-color-deep);
}
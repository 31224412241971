@import './Colors.css';

$link-hover-color: var(--secondaryColor);
$bs-border-radius: 1px;

.btn-primary {
    background-color: var(--secondaryColor) !important;
    border-color: var(--secondaryColor) !important;
    padding: 7px !important;
}

.btn-primary:hover {
    background-color: var(--secondaryColorHover) !important;
    border-color: var(--secondaryColorHover) !important;
}

.form-control {
    color: var(--primaryColor) !important;
    border-color: var(--secondaryColorLight) !important;
}

.row,
.row>* {
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
    width: 100%;
}

.form-control {
    padding: 7px;
}

.carousel-caption {
    position: relative;
    left: auto;
    right: auto;
    top: 0px;
    padding-top: 0;
    padding-bottom: 0x;
    height: 45px;
}

.carousel-control-prev {
    // background-color: #e0832b;
}

.carousel-control-prev-icon {
    position: relative;
    top: -25px;
    width: 35px;
    height: 35px;
}

.carousel-control-next-icon {
    position: relative;
    top: -25px;
    width: 35px;
    height: 35px;
}